











































































































export default {
  auth: true,
  data: () => ({

  }),
  methods: {
    async phoneCall(phoneNumber: string) {
      const telLink = `tel:${phoneNumber}`;
      window.location.href = telLink;
    },
  },
};
